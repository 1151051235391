import {Toast as ToastBootstrap, ToastContainer} from 'react-bootstrap'
import {toString} from 'lodash'
import {createContext, FC, useState} from 'react'

const toastDataObject = {
  success: {
    icon: (
      <span className='ki-duotone ki-shield-tick fs-3x text-success'>
        <span className='path1' />
        <span className='path2' />
      </span>
    ),
    bgColor: '#e8fff3',
    bgCloseIcon: 'text-success',
  },
  error: {
    icon: (
      <span className='ki-duotone ki-shield-cross fs-3x text-danger'>
        <span className='path1' />
        <span className='path2' />
        <span className='path3' />
      </span>
    ),
    bgColor: '#fff5f8',
    bgCloseIcon: 'text-danger',
  },
}

//context
interface ToastContextProps {
  showToast?: (status: 'success' | 'error' | 'info', title: string, content: string) => void
}
export const ToastContext = createContext<ToastContextProps>({})
//toast
interface Props {
  status: string
  title?: string
  content?: string
  closeToast?: () => void
}
const Toast: FC<Props> = ({status, title, content, closeToast}) => {
  const toastData = (toastDataObject as any)[toString(status)]

  return (
    <ToastContainer className='p-3 position-fixed bottom-0 end-0 zindex-tooltip'>
      <ToastBootstrap
        className={`d-inline-block p-2`}
        animation={true}
        autohide={false}
        delay={2000}
        onClose={closeToast}
        style={{
          backgroundColor: toastData?.bgColor,
        }}
      >
        <ToastBootstrap.Body className='d-flex justify-content-between align-items-center'>
          {toastData?.icon}
          <div className='d-flex flex-column justify-content-start w-100 ms-2'>
            <h5>{title}</h5>
            <span className='fs-7 text-gray-700'>{content}</span>
          </div>
          <div onClick={closeToast} style={{cursor: 'pointer'}}>
            <span className={`ki-duotone ki-cross fs-2x ${toastData?.bgCloseIcon}`}>
              <span className='path1' />
              <span className='path2' />
            </span>
          </div>
        </ToastBootstrap.Body>
      </ToastBootstrap>
    </ToastContainer>
  )
}
//provider
const ToastProvider: FC<{children: React.ReactNode}> = ({children}) => {
  const [toastProps, setToastProps] = useState({status: '', title: '', content: ''})
  const [isShowToast, setIsShowToast] = useState(false)

  const showToast = (status: 'success' | 'error' | 'info', title: string, content: string) => {
    setToastProps({status, title, content})
    setIsShowToast(true)
    setTimeout(() => setIsShowToast(false), 2000)
  }
  const closeToast = () => {
    setIsShowToast(false)
  }
  return (
    <ToastContext.Provider value={{showToast}}>
      {isShowToast && <Toast {...toastProps} closeToast={closeToast} />}
      {children}
    </ToastContext.Provider>
  )
}

export {ToastProvider}
