import axios, { AxiosResponse } from 'axios'
import { ConfigsQueryResponseGraphQL, GetEwalletConfigurationsQueryData } from './_models'

const EWALLET_API_ENDPOINT = process.env.REACT_APP_EWALLET_API_ENDPOINT
const EWALLET_CONFIG_API_ENDPOINT = `${EWALLET_API_ENDPOINT}/graphql`

const getEwalletConfigurations = async (): Promise<
  GetEwalletConfigurationsQueryData | undefined
> => {
  const response: AxiosResponse<ConfigsQueryResponseGraphQL> = await axios.post(
    EWALLET_CONFIG_API_ENDPOINT,
    {
      query: `
      query getEwalletConfigurations($isUsedBank: Boolean) {
        getEwalletConfigurations(isUsedBank: $isUsedBank) {
          bankCash {
            key
            value
          }
          paymentStatusType {
            key
            value
          }
          paymentMethodType {
            key
            value
          }
          paymentMethodSocial {
            key
            value
            logo
            amount
          }
          paymentMethodTypeSocial {
            key
            value
            logo
            amount
          }
        }
      }
    `,
    }
  )
  return response.data.data
}

export { getEwalletConfigurations }

