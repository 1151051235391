import {Suspense} from 'react'
import {Outlet} from 'react-router-dom'
import {LocaleProvider} from '../_biha/i18n/LocaleProvider'
import {MasterInit} from '../_biha/layout/MasterInit'
import {LayoutProvider, LayoutSplashScreen} from '../_biha/layout/core'
import {ToastProvider} from './modules/apps/components/toast/Toast'
import {AuthInit} from './modules/auth'
import {AddressProvider} from './modules/major/address/AddressProvider'
import {ConfigurationProvider as EwalletConfigurationProvider} from './modules/major/config/ConfigurationProvider'
import {ConfigurationProvider} from './modules/major/configs/ConfigurationProvider'
import {UserConfigurationProvider} from './modules/major/user-config/UserConfigProvider'

const App = () => {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <LocaleProvider>
        <LayoutProvider>
          <AuthInit>
            <AddressProvider>
              <UserConfigurationProvider>
                <ConfigurationProvider>
                  <EwalletConfigurationProvider>
                    <ToastProvider>
                      <Outlet />
                    </ToastProvider>
                    <MasterInit />
                  </EwalletConfigurationProvider>
                </ConfigurationProvider>
              </UserConfigurationProvider>
            </AddressProvider>
          </AuthInit>
        </LayoutProvider>
      </LocaleProvider>
    </Suspense>
  )
}

export {App}
