import axios, {AxiosResponse} from 'axios'
import {ConfigsQueryResponseGraphQL, GetUserConfigurationsQueryData} from './_models'

const MAIN_API_ENDPOINT = process.env.REACT_APP_MAIN_API_ENDPOINT
const MAIN_CONFIG_API_ENDPOINT = `${MAIN_API_ENDPOINT}/users/graphql`

const getUserConfigurations = async (): Promise<GetUserConfigurationsQueryData | undefined> => {
  const response: AxiosResponse<ConfigsQueryResponseGraphQL> = await axios.post(
    MAIN_CONFIG_API_ENDPOINT,
    {
      query: `
      query {
        getUserConfigurations {
          bankEnum {
            key
            value
          }
          careerEnum {
            key
            value
          }
          companyFields {
            key
            value
          }
          industryGroupEnum {
            key
            value
          }
          memberPrice
          memberStatusEnum {
            key
            value
          }
          premiumRequestStatus {
            key
            value
          }
          tollStatusEnum {
            key
            value
          }
          userEnum {
            key
            value
          }
          userType {
            key
            value
          }
        }
      }
    `,
    }
  )
  return response.data.data
}

export {getUserConfigurations}
