import {FormikErrors} from 'formik'
import {
  filter,
  forEach,
  gt,
  includes,
  isEqual,
  isNil,
  join,
  keys,
  replace,
  size,
  toLower,
  toString,
} from 'lodash'
import {Module} from '../../app/modules/auth'
import {RouterProps} from '../layout/components/header/header-menus/routers'

const removeVietnameseAccents = (text: string | null | undefined) =>
  isNil(text)
    ? ''
    : replace(replace(toLower(text.normalize('NFD')), /đ/g, 'd'), /[\u0300-\u036f]/g, '')

const formatFileSize = (bytes: number, decimalPoint?: number) => {
  if (isEqual(bytes, 0)) return '0 Bytes'
  let k = 1000,
    dm = decimalPoint || 2,
    sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
    i = Math.floor(Math.log(bytes) / Math.log(k))
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}

const getFieldErrorNames = <T extends any>(formikErrors: FormikErrors<T>) => {
  const transformObjectToDotNotation = (object: any, prefix = '', result: string[] = []) => {
    forEach(keys(object), (key) => {
      const value = object[key]
      if (!value) return
      const nextKey = prefix ? `${prefix}.${key}` : key
      if (isEqual(typeof value, 'object')) {
        transformObjectToDotNotation(value, nextKey, result)
      } else {
        result.push(nextKey)
      }
    })
    return result
  }
  return transformObjectToDotNotation(formikErrors)
}

const numberWithCommas = (value?: string | number | null, dot?: boolean) => {
  if (!value) return ''
  value = toString(value)
  var pattern = /(\d)(?=(\d{3})+(?!\d))/g
  value = value.replace(pattern, `$1${dot ? '.' : ','}`)
  return value
}
const filterRoutersAppByModule = (routesApp: RouterProps[], modules?: Module[]) => {
  const routers = filter(
    routesApp,
    (route) => (route.module && includes(modules, route.module)) || !route.module
  )
  return routers
}
function formatCustomString(
  inputString: string,
  firstChunkSize: number,
  chunkSize?: number
): string {
  const firstChunk = inputString.slice(0, firstChunkSize)
  const remainingString = inputString.slice(firstChunkSize)
  const chunks = remainingString.match(new RegExp(`\\d{1,${chunkSize ?? 3}}`, 'g'))
  if (chunks) {
    return `${firstChunk} ${join(chunks, ' ')}`
  } else {
    return inputString
  }
}
const phoneNumberWithSpaces = (phoneNumber: string) => {
  const phoneNumberLength = size(phoneNumber)
  const remainderOf3 = phoneNumberLength % 3
  if (gt(phoneNumberLength, 4)) {
    switch (remainderOf3) {
      case 0:
        return formatCustomString(phoneNumber, 3, 3)
      case 1:
        return formatCustomString(phoneNumber, 4, 3)
      case 2:
        return formatCustomString(phoneNumber, 2, 3)
      default:
        return formatCustomString(phoneNumber, 3, 3)
    }
  } else return phoneNumber
}

export {
  filterRoutersAppByModule,
  formatFileSize,
  getFieldErrorNames,
  numberWithCommas,
  phoneNumberWithSpaces,
  removeVietnameseAccents,
}
