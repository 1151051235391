import clsx from 'clsx'
import {
  debounce,
  filter,
  first,
  gt,
  includes,
  isEqual,
  isNil,
  isString,
  map,
  size,
  toLower,
  trim,
  upperCase,
} from 'lodash'
import {FC, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {NavLink} from 'react-router-dom'
import {FileWithFullUrls, useAuth} from '../../../../../app/modules/auth'
import {KTSVG, toAbsoluteUrl} from '../../../../helpers'
import {filterRoutersAppByModule} from '../../../../utils'
import {routersApp} from './routers'

const Spotlight: FC = () => {
  const [show, setShow] = useState(false)
  const [searchValue, setSearchValue] = useState('')
  const [navigationObjectsData, setNavigationObjectsData] = useState(routersApp)
  const userAvatarClass = 'symbol-36px symbol-md-40px'
  const {currentUser} = useAuth()
  const filterRouterByModules = filter(
    filterRoutersAppByModule(navigationObjectsData, currentUser?.modules),
    (router) => !isEqual(router?.id, 0)
  )
  function handleShow() {
    setShow(true)
  }
  function handleClose() {
    setShow(false)
  }
  const handleChangeSearchValue = (event: any) => {
    const inputValue = event.target.value
    setSearchValue(inputValue)
    debouncedSearch(inputValue)
  }
  const debouncedSearch = debounce((searchTerm) => {
    setNavigationObjectsData(
      filter(routersApp, (item) => includes(trim(toLower(item.title)), trim(toLower(searchTerm))))
    )
  }, 500)
  debounce(handleChangeSearchValue, 500)

  return (
    <div>
      <div
        className='d-flex flex-column  align-items-center justify-content-center pt-5 pb-3'
        onClick={handleShow}
      >
        <KTSVG path='/media/icons/modules/total.svg' className='svg-icon-3x' />
        <span className='mt-3 fs-7 fs-normal text-center'>Tất cả</span>
      </div>
      <Modal show={show} fullscreen={true}>
        <div
          className='bg-image'
          style={{
            backgroundImage: `url(${toAbsoluteUrl('/media/biha/background/background1.png')})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            width: '100%',
            height: '100vh',
          }}
        >
          <div className='px-4 py-3 d-flex justify-content-between'>
            <div className='d-flex align-items-center'>
              <span
                className='ki-duotone ki-left text-white  cursor-pointer '
                style={{fontSize: '3rem'}}
                onClick={handleClose}
              />
              <img
                src={toAbsoluteUrl('/media/logos/default-white.svg')}
                alt=''
                className='h-40px'
              />
            </div>
            <div className='d-flex align-items-center'>
              <span className='ki-duotone ki-notification-on text-white fs-1 me-5'>
                <span className='path1' />
                <span className='path2' />
                <span className='path3' />
                <span className='path4' />
                <span className='path5' />
              </span>
              <div className='d-flex align-items-center '>
                <div
                  className={clsx(
                    'cursor-pointer symbol d-flex align-items-center',
                    userAvatarClass
                  )}
                  data-kt-menu-trigger="{default: 'click'}"
                  data-kt-menu-attach='parent'
                  data-kt-menu-placement='bottom-end'
                >
                  {!isNil((currentUser?.avatar as FileWithFullUrls)?.originalUrl) ? (
                    <img
                      src={(currentUser?.avatar as FileWithFullUrls)?.originalUrl}
                      alt=''
                      className='rounded w-36px h-36px me-3'
                    />
                  ) : (
                    <div className='symbol-label rounded w-36px h-36px fs-4 text-gray-700 me-3'>
                      {upperCase(first(currentUser?.fullName))}
                    </div>
                  )}
                  <span className='fs-5 fw-bold text-white'>{currentUser?.fullName}</span>
                </div>
              </div>
            </div>
          </div>
          <Modal.Body style={{height: '80%'}}>
            <div className='d-flex justify-content-center align-items-center h-100'>
              <div className='d-flex flex-column justify-content-center' style={{width: '60%'}}>
                <div className='mx-auto text-center w-380px'>
                  <input
                    value={searchValue}
                    type='text'
                    placeholder='Tìm kiếm'
                    onChange={handleChangeSearchValue}
                    className='form-control form-control-solid w-100 ps-10 pe-8 fs-7 fw-bold h-40px py-0'
                  />
                </div>
                <div
                  className={`d-flex mt-15 row row-cols-5 ${
                    gt(size(filterRouterByModules), 5) ? '' : 'justify-content-center'
                  }`}
                  style={{minHeight: 320}}
                >
                  {map(filterRouterByModules, (router) => {
                    const isExternalLink = router?.path?.startsWith('https')
                    if (isExternalLink) {
                      return (
                        <div
                          key={router.id}
                          className={`  d-flex  justify-content-center  `}
                          onClick={handleClose}
                        >
                          <div
                            className=' rounded-3 d-flex  justify-content-center bg-hover-primary '
                            style={{
                              height: '140px',
                              width: '160px',
                            }}
                          >
                            <a
                              href={router.path || ''}
                              className='d-flex flex-column align-items-center pt-3'
                              target='_blank'
                              rel='noreferrer'
                            >
                              {isString(router.icon) && (
                                <img
                                  src={toAbsoluteUrl(router.icon)}
                                  alt=''
                                  className='h-80px py-3'
                                />
                              )}
                              <span
                                className='fs-7 fs-normal text-center text-white fw-bold'
                                style={{
                                  lineHeight: '18px',
                                  maxWidth: 140,
                                }}
                              >
                                {router.title}
                              </span>
                            </a>
                          </div>
                        </div>
                      )
                    }
                    return (
                      <div
                        key={router.id}
                        className={`d-flex justify-content-center`}
                        onClick={handleClose}
                      >
                        <div
                          className='rounded-3 d-flex justify-content-center bg-hover-primary '
                          style={{
                            height: '140px',
                            width: '160px',
                          }}
                        >
                          <NavLink
                            to={router.path || ''}
                            className={`d-flex flex-column align-items-center pt-3`}
                          >
                            {isString(router.icon) && (
                              <img
                                src={toAbsoluteUrl(router.icon)}
                                alt=''
                                className='h-80px py-3'
                              />
                            )}
                            <span
                              className='fs-7 fs-normal text-center text-white fw-bold'
                              style={{
                                lineHeight: '18px',
                                maxWidth: 140,
                              }}
                            >
                              {router.title}
                            </span>
                          </NavLink>
                        </div>
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
          </Modal.Body>
        </div>
      </Modal>
    </div>
  )
}

export {Spotlight}
