import clsx from 'clsx'
import {Link, useNavigate} from 'react-router-dom'
import {useLayout} from '../../../core'
import {usePageData} from '../../../core/PageData'

const PageTitle = () => {
  const navigate = useNavigate()
  const {backPageButton, pageTitle, pageDescription, pageBreadcrumbs} = usePageData()
  const {config, classes} = useLayout()
  const appPageTitleDirection = config.app?.pageTitle?.direction

  const back = () => {
    navigate(-1)
  }

  return (
    <div
      id='kt_page_title'
      data-kt-swapper='true'
      data-kt-swapper-mode='prepend'
      data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}"
      className={clsx(
        'page-title d-flex flex-wrap me-3',
        classes.pageTitle.join(' '),
        config.app?.pageTitle?.class,
        {
          'flex-column justify-content-center': appPageTitleDirection === 'column',
          'align-items-center': appPageTitleDirection !== 'column',
        }
      )}
    >
      {/* begin::Title */}
      {config.app?.pageTitle?.display && pageTitle && (
        <div className='d-flex'>
          {backPageButton && (
            <div>
              <button type='button' className='btn p-0 me-4 h-auto' onClick={back}>
                <span className='ki-duotone ki-left-square fs-2x text-primary text-hover-info'>
                  <span className='path1'></span>
                  <span className='path2'></span>
                </span>
              </button>
            </div>
          )}
          <div className='d-flex flex-column'>
            <h1
              className={clsx('page-heading d-flex text-dark fw-bold fs-1 my-0', {
                'flex-column justify-content-center': appPageTitleDirection,
                'align-items-center': !appPageTitleDirection,
              })}
            >
              {pageBreadcrumbs?.[0]?.title}
              {pageDescription && config.app?.pageTitle && config.app?.pageTitle?.description && (
                <span
                  className={clsx('page-desc text-muted fs-7 fw-semibold', {
                    'pt-2': appPageTitleDirection === 'column',
                  })}
                >
                  {config.app?.pageTitle?.direction === 'row' && (
                    <span className='h-20px border-1 border-gray-300 border-start ms-3 mx-2'></span>
                  )}
                  {pageDescription}{' '}
                </span>
              )}
            </h1>
            {pageBreadcrumbs &&
              pageBreadcrumbs.length > 0 &&
              config.app?.pageTitle &&
              config.app?.pageTitle?.breadCrumb && (
                <>
                  {config.app?.pageTitle?.direction === 'row' && (
                    <span className='h-20px border-gray-300 border-start mx-4'></span>
                  )}
                  <ul className='breadcrumb breadcrumb-separatorless fw-semibold fs-6 mt-2'>
                    {Array.from(pageBreadcrumbs).map((item, index) => (
                      <li
                        className={clsx('breadcrumb-item', {
                          'text-dark': !item.isSeparator && item.isActive,
                          'text-muted': !item.isSeparator && !item.isActive,
                        })}
                        key={`${item.path}${index}`}
                      >
                        {!item.isSeparator ? (
                          <Link className='text-primary text-hover-info' to={item.path}>
                            {item.title}
                          </Link>
                        ) : (
                          <span className='text-muted'>-</span>
                        )}
                      </li>
                    ))}
                    <li className='breadcrumb-item text-muted'>{pageTitle}</li>
                  </ul>
                </>
              )}
          </div>
        </div>
      )}
      {/* end::Title */}
    </div>
  )
}

export {PageTitle}
